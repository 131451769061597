// change cacheBusting value every 15 minutes
window.cacheBusting = (function getRandomValue() {
  const MILLISECONDS_IN_MINUTE = 15 * 1000;
  const MINUTES_IN_BLOCK = 15;

  const now = new Date();
  const minutesSinceEpoch = Math.floor(now.getTime() / MILLISECONDS_IN_MINUTE);
  const current15MinBlock = Math.floor(minutesSinceEpoch / MINUTES_IN_BLOCK);

  const randomValue = Math.abs(Math.sin(current15MinBlock) * 1000);

  return Math.floor(randomValue);
})();

import('./bootstrap.js');
